.cloud{
  position:absolute;
}
.cloud1{
  width: 20rem;
  top:-3rem;
  animation: 80.0s cloudFloat1 infinite linear;
}
.cloud2{
  width: 20rem;
  top:0;
  animation: 77.0s cloudFloat2 infinite linear;
}
.cloud3{
  width: 22rem;
  top:4rem;
  animation: 75.0s cloudFloat3 infinite linear;
}
.cloud4{
  width: 16rem;
  top:8rem;
  animation: 70.0s cloudFloat4 infinite linear;
}

@keyframes cloudFloat1 {
  0% {
    left: 10%;
    opacity: 0.5;
  }
  30% {
    left: calc(0% - 20rem);
    opacity: 0.5;
  }
  30.01% {
    left: calc(0% - 20rem);
    opacity: 0;
  }
  30.02% {
    left: 100%;
    opacity: 0;
  }
  30.03% {
    left: 100%;
    opacity: 0.5;
  }
  100% {
    left: 10%;
    opacity: 0.5;
  }
}

@keyframes cloudFloat2 {
  0% {
    left: 50%;
    opacity: 0.5;
  }
  50% {
    left: calc(0% - 20rem);
    opacity: 0.5;
  }
  50.01% {
    left: calc(0% - 20rem);
    opacity: 0;
  }
  50.02% {
    left: 100%;
    opacity: 0;
  }
  50.03% {
    left: 100%;
    opacity: 0.5;
  }
  100% {
    left: 50%;
    opacity: 0.5;
  }
}


@keyframes cloudFloat3 {
  0% {
    left: 75%;
    opacity: 0.3;
  }
  75% {
    left: calc(0% - 20rem);
    opacity: 0.3;
  }
  75.01% {
    left: calc(0% - 20rem);
    opacity: 0;
  }
  75.02% {
    left: 100%;
    opacity: 0;
  }
  75.03% {
    left: 100%;
    opacity: 0.3;
  }
  100% {
    left: 75%;
    opacity: 0.3;
  }
}

@keyframes cloudFloat4 {
  0% {
    left: 100%;
    opacity: 0.3;
  }
  99.9% {
    left: calc(0% - 20rem);
    opacity: 0.3;
  }
  99.91% {
    left: calc(0% - 20rem);
    opacity: 0;
  }
  99.92% {
    left: 100%;
    opacity: 0;
  }
  100% {
    left: 100%;
    opacity: 0.3;
  }
}